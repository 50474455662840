import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SVG_CONFIG } from './constants/svg-icons';

/**
 * Icon's type
 */
export enum IconType {
  FontAwesome = 'awesome',
  Material = 'material',
  SVG = 'svg',
}

@Component({
  standalone: true,
  selector: 'fo-icon',
  templateUrl: './fo-icon.component.html',
  styleUrls: ['./fo-icon.component.scss'],
  imports: [CommonModule, MatIconModule],
})
export class FoIconComponent implements OnInit {
  /**
   * Icon name
   */
  @Input() name: string;

  /**
   * Icon type
   */
  @Input() type?: IconType;

  constructor() {}

  ngOnInit() {
    // Set type if no one defined
    if (!this.type) this.type = this.defaultType;
  }

  /**
   * Get the icon type given its name
   * SVG icons are the priority, then FontAwesome icons and finally Material icons
   * @return the icon type founded
   */
  private get defaultType(): IconType {
    return this.isSVGIcon() ? IconType.SVG : IconType.Material;
  }

  /**
   * The icon is SVG type
   * @returns
   */
  isSVGIcon(): boolean {
    return this.type ? this.type === IconType.SVG : !!SVG_CONFIG.icons[this.name];
  }
}
